<template>
  <div class="main-block swap-card">
    <div>
      <div class="swap-card__header">
        <img :src="require(`@/assets/img/${item.token}-token.svg`)" :alt="item.token">
        {{ contract.toUpperCase() }}
      </div>
      <div class="swap-card__icons">
        <div class="swap-card__icon">
          <img :src="require(`@/assets/img/bridge/${item.from}-logo.svg`)" :alt="item.token">
        </div>
        <div class="swap-card__arrow">
          <img src="@/assets/img/bridge/arrow.svg" alt="arrow">
        </div>
        <div class="swap-card__icon">
          <img :src="require(`@/assets/img/bridge/${item.to}-logo.svg`)" :alt="item.to">
        </div>
      </div>
    </div>
    <div>
      <div class="swap-card__swap" v-if="allowance[item.token] > 0">
        <div v-if="isToClaim">
          <div class="swap-card__amount text-center"> Swap amount in process </div>
          <div class="swap-card__amount-swap"> {{ orderAmount }} </div>
          <div class="swap-card__token">{{ contract }}</div>
        </div>
        <div v-else>
          <div class="swap-card__amount"> Enter amount </div>
          <div class="swap-card__input main-block">
            <input
              type="number"
              v-model.number="amount"
              @input="changeAmount"
              class="swap-card__field"
              :class="{ 'swap-card__field_danger': amount > balance }"
              placeholder="0"
            >
            <v-btn fab small @click="max()"> max </v-btn>
          </div>
          <div class="swap-card__balance">
            You have
            <span> {{ numeral(balance).format('0,0.00') }} {{ contract.toUpperCase() }} </span>
          </div>
        </div>

        <v-btn rounded block class="bridge-button btn" v-if="isToClaim" @click="toClaim()">
          To claim
        </v-btn>
        <v-btn
          rounded
          block
          class="bridge-button btn"
          v-else
          @click="swap()"
          :loading="swapLoading"
          :disabled="swapDisable"
        >
          Swap
        </v-btn>
      </div>
      <v-btn
        rounded
        block
        class="bridge-button btn"
        v-else
        @click="approveSwap()"
        :loading="approveLoading"
      >
        Approve
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import numeral from 'numeral';

export default {
  name: 'SwapCard',

  props: {
    item: Object,
  },

  data() {
    return {
      numeral,
      approve: true,
      amount: '',
      minAmount: 0,
      swapLoading: false,
      approveLoading: false,
      interval: null,
      isMax: false,
    };
  },

  computed: {
    ...mapGetters({
      allowance: 'Bridge/allowance',
      chains: 'Bridge/chains',
      testnets: 'Bridge/testnets',
      networks: 'Bridge/networks',
      chainId: 'User/chainId',
      ethAddress: 'User/ethAddress',
      orders: 'Bridge/activeOrders',
      processingOrders: 'Bridge/processingOrders',
    }),

    ...mapState({
      milkBalance: (state) => state.Milk.milkBalance,
      shakeBalance: (state) => state.Shake.balance,
    }),

    contract() {
      return this.item.token === 'milk' ? 'milk2' : this.item.token;
    },

    orderAmount() {
      const data = [...this.orders, ...this.processingOrders];
      const res = data.find((order) => (order.networkFrom === this.chains[this.chainId]
        && order.networkTo === this.toChain && order.ticker === this.contract.toUpperCase()));
      return numeral(res?.amount).format('0,0.000000') || 0;
    },
    isToClaim() {
      const res = this.orders.find((order) => (order.networkFrom === this.chains[this.chainId]
        && order.networkTo === this.toChain && order.ticker === this.contract.toUpperCase()));
      return Boolean(res);
    },
    swapDisable() {
      const res = this.processingOrders.find((order) => (
        order.networkFrom === this.chains[this.chainId]
        && order.networkTo === this.toChain && order.ticker === this.contract.toUpperCase()));
      return Boolean(res);
    },

    balance() {
      if (this.contract === 'milk2') {
        return this.milkBalance?.toNumber() ?? 0;
      }
      return this.shakeBalance?.toNumber() ?? 0;
    },

    tokenAddress() {
      return this.$contracts.getAddress(this.contract);
    },

    fromChain() {
      return this.chains[this.chainId];
    },

    toChain() {
      if (this.testnets.includes(this.fromChain)) {
        return this.networks.testnet[this.item.to];
      }
      return this.networks.mainnet[this.item.to];
    },

    toChainId() {
      let id = null;
      Object.keys(this.chains).forEach((el) => {
        if (this.chains[el] === this.toChain) {
          id = el;
        }
      });
      return Number(id);
    },

    tokenAddressTo() {
      return this.$contracts.getContractAddress(this.toChainId, this.contract);
    },
  },

  methods: {
    changeAmount() {
      this.isMax = false;
    },
    async toClaim() {
      try {
        if (this.$route.name !== 'bridgeClaim') {
          await this.$router.push('/new-bridge/claim');
        }
        if (this.chainId !== this.toChainId) {
          await this.$chain.switchChain(this.toChainId);
        }
      } catch (e) {
        console.log(e);
      }
    },

    max() {
      this.isMax = true;
      this.amount = this.balance;
    },

    async approveSwap() {
      try {
        await this.updateAllowance();
        this.approveLoading = true;
        await this.$store.dispatch('Actions/approve', {
          contract: this.contract,
          spender: 'shadowBridge',
          amount: 10 ** 26,
        });
        this.approveLoading = false;
      } catch (e) {
        console.log(e);
      } finally {
        await this.$store.dispatch('Bridge/swapAllowance');
        this.approveLoading = false;
      }
    },

    async swap() {
      if (this.amount <= 0) {
        this.$notify({ group: 'info', title: 'Please enter amount' });
      } else if (this.amount <= this.balance) {
        try {
          this.swapLoading = true;
          let weiAmount = this.$web3.utils.toWei(this.amount.toString());
          if (this.isMax) {
            const token = this.$contracts.getContract(this.contract);
            weiAmount = await token.methods.balanceOf(this.ethAddress).call();
            console.log(1, weiAmount);
          }
          const res = await this.getSwapData(this.$web3.utils.fromWei(weiAmount));

          if (res.status === 'success') {
            await this.payIn(res.data, weiAmount);
            await this.toClaim();
          }
          this.swapLoading = false;
        } catch (e) {
          console.log(123, e);
          this.isMax = false;
          this.amount = '';
          this.swapLoading = false;
          let msg = e?.response?.data?.message ?? e?.message;
          if (msg.includes('Transaction has been reverted by the EVM')) {
            msg = 'Transaction has been reverted by the EVM';
          }
          if (msg) this.$notify({ group: 'info', title: msg });
          await this.$store.dispatch('Bridge/orderCancel');
        }
      } else {
        this.$notify({ group: 'info', title: 'Insufficient funds' });
      }
    },

    async getSwapData(amount) {
      const res = await this.$store.dispatch('Bridge/getSwapData', {
        fromChain: this.chains[this.chainId],
        toChain: this.toChain,
        amount,
        tokenAddress: this.tokenAddress,
        tokenAddressTo: this.tokenAddressTo,
        ticker: this.contract.toUpperCase(),
      });
      return res;
    },

    async payIn(data, amount) {
      const payInObject = {
        orderId: data.orderId,
        amount,
        tokenAddress: this.tokenAddress,
        msgForSign: data.message,
        signature: data.signature,
      };

      await this.$store.dispatch('Bridge/payIn', payInObject);
    },

    async updateAllowance() {
      this.interval = setInterval(async () => {
        await this.$store.dispatch('Bridge/swapAllowance');
        if (this.allowance[this.item.token]) {
          clearInterval(this.interval);
        }
      }, 4000);
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
.swap-card{
  padding: 5rem 2rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__header{
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 155%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    margin-bottom: 2.6rem;
    img{
      width: 3.2rem;
      margin-right: 1.2rem;
    }
  }
  &__token{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 155%;
    letter-spacing: 0.03em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1.4rem;
  }
  &__balance{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 155%;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    justify-content: space-between;
    margin-top: 1.4rem;
    span{
      font-weight: 700;
    }
  }
  &__approve{
    margin-top: 6rem;
    width: 100%;
  }
  &__input{
    background: linear-gradient(103.94deg, #FDFAFB -1.71%, #F7FBFC 45.51%);
    box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.9), -1px -1px 5px rgba(182, 169, 173, 0.3),
      inset -2px -2px 2px rgba(182, 169, 173, 0.2), inset 1px 1px 4px rgba(182, 169, 173, 0.2),
      inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(182, 169, 173, 0.2);
    border-radius: 4.6rem;
    padding: 0.5rem 0.5rem 0.5rem 2.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 155%;
    .v-btn{
      background-color: #F8FCFD !important;
      box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25), 2px 2px 20px rgba(212, 202, 205, 0.2),
      -10px -10px 15px #FFFFFF, 10px 10px 20px rgba(181, 155, 155, 0.25),
      inset 2px 2px 6px #FFFFFF, inset -1px -1px 2px rgba(181, 155, 155, 0.2);
      text-transform: lowercase;
      height: 5rem;
      width: 5rem;
      .v-btn__content{
        color: #283287;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 155%;
      }
    }
  }
  &__field{
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 155%;
    letter-spacing: 0.03em;
    color: #7478A0;
    border: none;
    outline: none;
    width: calc(100% - 5rem);
    &::placeholder{
      color: #7478A0;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
    &_danger{
      color: red !important;
    }
  }
  &__amount{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 160%;
    margin-bottom: 1.9rem;
    &-swap{
      text-align: center;
      font-size: 3rem;
      font-weight: 600;
      padding: 0.7rem 0;
    }
  }
  &__btn{
    width: 100%;
    margin-top: 2.4rem;
  }
  &__icons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
  }
  &__arrow{
    padding: 0 1rem;
    img{
      height: 3.5rem;
    }
  }

  &__icon{
    height: 7rem;
    background: #F8FCFD;
    border-radius: 4rem;
    box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25), 2px 2px 20px rgba(212, 202, 205, 0.2),
    -10px -10px 15px #FFFFFF, 10px 10px 20px rgba(181, 155, 155, 0.25),
    inset 2px 2px 6px #FFFFFF, inset -1px -1px 2px rgba(181, 155, 155, 0.2);
    img{
      height: 100%;
      padding: 1.1rem;
    }
  }

  body.binance &{
    .v-btn{
      background: linear-gradient(180deg, #FFE55B 0%, #DEBE17 100%);
      box-shadow: none;
      .v-btn__content{
        color: #0F1929;
      }
    }
    &__icon{
      background: #2F2F2F;
      box-shadow: 6px 6px 8px #16161B, -6px -6px 12px #333342, inset 2px 2px 5px #47475A,
        inset -1px -1px 4px rgba(0, 0, 0, 0.69), inset -3px -3px 12px rgba(4, 4, 10, 0.6),
        inset 2px 2px 2px rgba(255, 255, 255, 0.3);
    }
    &__field, &__field::placeholder{
      color: white;
    }
  }

  body.avalanche &{
    &__field, &__field::placeholder{
      color: #151515;
    }
  }
}
</style>
