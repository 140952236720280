<template>
  <v-col class="main-wrap main-wrap--narrow bridge-swap">
    <OutOfService />
    <div class="page-content">
      <v-row class="page-content" align="stretch" v-if="chainName in networks">
        <v-col class="col-12 col-md-6" v-for="(network, i) of swapData" :key="i">
          <div class="bridge-swap__network main-block">
            <div class="bridge-swap__title">
              {{ network.title }}
            </div>
            <v-row>
              <v-col v-for="(item, j) of network.items" :key="`item-${j}`">
                <SwapCard :item="item" />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div class="bridge-swap__to-claim mb-2">
        To complete the swap, please proceed to the
        <router-link to="/new-bridge/claim">claim page</router-link>.
      </div>
      <div class="bridge-swap__to-claim">
        To check on your swap transactions, please proceed to the
        <router-link to="/new-bridge/explorer">explorer page</router-link>.
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import SwapCard from '@/components/Bridge/SwapCard';
import { swapData } from '@/constants/shadowBridge';
import OutOfService from '@/components/Bridge/OutOfService';

export default {
  name: 'swap',
  components: { OutOfService, SwapCard },
  data() {
    return {
      networks: swapData,
    };
  },

  computed: {
    ...mapGetters({
      userAddress: 'User/ethAddress',
      chainId: 'User/chainId',
    }),

    chainName() {
      return this.$chain.getName();
    },

    swapData() {
      return this.networks[this.chainName];
    },
  },

  watch: {
    async userAddress() {
      await this.updateData();
    },
    async chainId() {
      await this.updateData();
    },
  },

  methods: {
    async updateData() {
      await Promise.all([
        this.$store.dispatch('Bridge/swapAllowance'),
        this.$store.dispatch('Milk/update', {}),
        this.$store.dispatch('Shake/update', {}),
      ]);
    },
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch('Bridge/getActiveOrders'),
      this.$store.dispatch('Bridge/swapAllowance'),
    ]);
  },
};
</script>

<style lang="scss">
.bridge-swap{
  width: 100%;
  max-width: 151rem;
  &__network{
    margin-bottom: 3rem;
    height: 100%;
    padding: 4rem 3rem 0rem;
  }
  &__title{
    font-weight: bold;
    font-size: 3.4rem;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #283287;
    margin-bottom: 3rem;
  }
  &__to-claim{
    text-align: center;
    font-size: 2rem;
  }
  body.binance &{
    &__title{
      color: white;
    }
  }

  body.avalanche &{
    &__title, .v-btn__content{
      color: #151515;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &__network{
      padding: 3rem 2rem 0rem;
    }
  }
}
</style>
